/* General Styling */
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

/* Hero Section */
.hero-section {
  width: 100%;
  background: linear-gradient(135deg, #4e54c8, #8f94fb);
  color: white;
  text-align: center;
  padding: 60px 20px;
}

.hero-section h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.hero-text {
  font-size: 18px;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
}

/* Card Design */
.card {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  margin: 30px;
  text-align: center;
}

.card h2 {
  color: #333;
  font-size: 28px;
  margin-bottom: 15px;
}

.info-text {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}

.steps-list {
  text-align: left;
  font-size: 16px;
  color: #555;
  margin-bottom: 30px;
  padding-left: 20px;
}

.steps-list li {
  margin-bottom: 10px;
}

/* Buttons */
.primary-button {
  background-color: #6c63ff;
  color: white;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.primary-button:hover {
  background-color: #5753d6;
}

.danger-button {
  background-color: #ff4d4d;
  color: white;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s ease;
}

.danger-button:hover {
  background-color: #e63946;
}

/* Confirm Box */
.confirm-box {
  margin-top: 30px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
}

.confirm-box h3 {
  font-size: 22px;
  color: #444;
  margin-bottom: 15px;
}

.confirm-text {
  color: #666;
  font-size: 16px;
  margin-bottom: 15px;
}

.input-box {
  padding: 12px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  margin-bottom: 10px;
}

.error-message {
  color: #e63946;
  font-size: 14px;
  margin-top: 10px;
}

/* Footer */
.footer {
  margin-top: 50px;
  font-size: 14px;
  color: #999;
}
